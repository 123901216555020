<template>
    <div>

        <el-main style=" padding-top: 5px;padding-bottom: initial  ">
            <el-form label-width="80px" @submit.native.prevent="search" >
                <el-row>
                    <el-col :lg="24">
                        <div :class='[this.$store.getters.getRunTimeTest ? "warning" : "tip"]'>
                            <el-row>
                                <el-col :lg="24" style="line-height: 27px;">
                                    <strong style=" font-size: 16px; ">当前：应收客户账-客户明细</strong>
                                </el-col>
                            </el-row>
                        </div>
                    </el-col>
                    <el-col :lg="24">
                        <el-row>
                            <el-col :sm="12" :md="4">
                                <el-date-picker
                                        style=" margin-top: 8px; width: 220px"
                                        v-model="pickerDate"
                                        type="daterange"
                                        ref="pickerDate"
                                        size="mini"
                                        :clearable="false"
                                        :picker-options="pickerOptions"
                                        range-separator="至"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期"
                                        align="right">
                                </el-date-picker>
                            </el-col>
                            <el-col :sm="12" :md="5">
                                <el-form-item label="公司:">
                                    <el-select v-model="formData.company_name"
                                               @focus="$_searchCustomerList('')"
                                               @change="companyNameChangeEvent($event,'customer_name')"
                                               style="margin-left: 12px;width: 100%"
                                               size="mini"
                                               default-first-option no-data-text="没有搜索结果!" placeholder="可搜索查询"
                                               filterable remote
                                               :remote-method="$_searchCustomerList">
                                        <el-option v-for="(item,key) in $store.getters.getCustomerList" :key="key"
                                                   :label="item.customer_name" :value="item">
                                            <span style="margin-right:8px;">{{item.customer_name}}</span>
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :sm="12" :md="5">
                                <el-button style=" margin-top: 8px; margin-left: 20px" type="primary" round @click="search()"
                                           size="mini">搜索
                                </el-button>
                                <el-button v-if="$_getActionKeyListBoolean(['/receipt/saveOrUpdateReceipt'])" type="primary" size="mini" @click="$_openBill($refs.xGrid.getCurrentRecord())"
                                           :disabled="$refs.xGrid == null || $refs.xGrid.getCurrentRecord() == null"
                                           style="margin-bottom: 2px;margin-top: 2px">修改
                                </el-button>
                                <el-button type="primary" size="mini" @click="$_openBill($refs.xGrid.getCurrentRecord(),'print')"
                                           :disabled="$refs.xGrid == null || $refs.xGrid.getCurrentRecord() == null"
                                           style="margin-bottom: 2px;margin-top: 2px">查看
                                </el-button>
                                <el-button type="primary" size="mini" @click="$router.back()"
                                           style="margin-bottom: 2px;margin-top: 2px">返回
                                </el-button>
                            </el-col>
                            <el-col :sm="12" :md="3">
                                <el-form-item label="上期数:">
                                    <el-input v-model="before_balance_company" :readonly="true" size="mini"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :sm="12" :md="3">
                                <el-form-item label="结存数:">
                                    <el-input v-model="end_balance_company" :readonly="true" size="mini"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :sm="12" :md="4">
                                <el-row>
                                    <el-col class="pull-left">
                                        <vxe-toolbar class="pull-left" style=" height: 42px; margin-left: 12px" custom print export
                                                     ref="xToolbar2">
                                        </vxe-toolbar>
                                    </el-col>
                                </el-row>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :lg="24">
                        <vxe-grid
                                border
                                resizable
                                show-overflow
                                keep-source
                                ref="xGrid"
                                align="right"
                                size="mini"
                                :height="(this.getViewHeight() - 130)+''"
                                highlight-hover-row
                                highlight-current-row
                                show-footer
                                :row-class-name="tableRowClassName"
                                :print-config="{}"
                                :data="tableDataMain"
                                :columns="tableColumn"
                                :footer-method="footerMethod"
                                :mouse-config="{selected: false}"
                                :export-config="{mode:'current',type: ['xlsx'] ,types: ['xlsx', 'csv', 'html', 'xml', 'txt'],isFooter:true,isColgroup:true}"
                                :edit-config="{showIcon:true,trigger: 'click', mode: 'cell', showStatus: true}"
                                :keyboard-config="{enterToTab:true,isArrow: true, isDel: false, isEnter: true, isTab: true,isEdit:true}">
                            <template #pager>
                                <vxe-pager
                                        :layouts="['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total']"
                                        :page-sizes="[50,500,1000,2500,5000,50000]"
                                        :current-page.sync="tablePage.currentPage"
                                        :page-size.sync="tablePage.pageSize"
                                        :total="tablePage.total"
                                        @page-change="handlePageChange">
                                </vxe-pager>
                            </template>
                        </vxe-grid>
                    </el-col>
                </el-row>
            </el-form>npm
        </el-main>
    </div>
</template>

<script>
    import {filterRender} from "@/utils/gird-filter";

    export default {

        name: "ClientReceivableDetail",
        data() {
            return {
                before_balance_company:'',
                end_balance_company:'',
                pickerDate: [],
                formData:{
                    company_uuid :'',
                    company_name :'',
               },
                tablePage: {
                    total: 0,
                    currentPage: 1,
                    pageSize: 500,
               },
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近一年',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '本月',
                        onClick(picker) {
                            var now = new Date();
                            var year = now.getFullYear();
                            var month = now.getMonth() + 1;
                            if (month < 10) {
                                month = '0' + month;
                           }
                            // 本月第一天
                            var startDate = new Date(year + '-' + month + '-01 00:00:00');
                            picker.$emit('pick', [startDate, new Date(year + '-' + month + '-' + new Date(year, month, 0).getDate() + ' 23:59:00')]);
                       }
                   }, {
                        text: '上个月',
                        onClick(picker) {
                            var now = new Date();
                            var year = now.getFullYear();
                            var month = now.getMonth();
                            if (month == 0) {
                                month = 12;
                                year = year - 1;
                           }
                            if (month < 10) {
                                month = '0' + month;
                           }
                            var myDate = new Date(year, month, 0);
                            // 上个月第一天
                            var startDate = new Date(year + '-' + month + '-01 00:00:00');
                            // 上个月最后一天
                            var endDate = new Date(year + '-' + month + '-' + myDate.getDate() + ' 23:59:00');
                            picker.$emit('pick', [startDate, endDate]);
                       }
                   }]
               },
                tableColumn: [
                    {
                        field:'type_name', width: 80, title: '单据类型', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/clientReceivableDetail/searchHeard', this.pickerDate,this.formData)
                       }
                   },{
                        field:'bill_date', width: 180, title: '单据日期', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/clientReceivableDetail/searchHeard', this.pickerDate,this.formData)
                       },formatter: ({cellValue}) => {return this.formatDate(cellValue,'yyyy-MM-dd')}
                   }, {
                        field:'bill_id', width: 165, title: '单号', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/clientReceivableDetail/searchHeard', this.pickerDate,this.formData)
                       }
                   },  {
                        field:'subject_name_cash_data', width: 220, title: '科目名称', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/clientReceivableDetail/searchHeard', this.pickerDate,this.formData)
                       }
                   }, /*{
                        field:'before_money', width: 100, title: '上期数', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                       }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/clientReceivableDetail/searchHeard', this.pickerDate,this.formData)
                       }
                   },*/{
                        field:'in_money', width: 140, title: '进金额(本期销售单)', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                       }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/clientReceivableDetail/searchHeard', this.pickerDate,this.formData)
                       }
                   },{
                        field:'out_money', width: 140, title: '出金额(本期收款)', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                       }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/clientReceivableDetail/searchHeard', this.pickerDate,this.formData)
                       }
                   },{
                        field:'before_money',visible:false, width: 100, title: '小结', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                       }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/clientReceivableDetail/searchHeard', this.pickerDate,this.formData)
                       }
                   },{
                        field:'b_money', width: 100, title: '结存', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/clientReceivableDetail/searchHeard', this.pickerDate,this.formData)
                       }
                   },{
                        field:'sales_man', width: 70, title: '业务员', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/clientReceivableDetail/searchHeard', this.pickerDate,this.formData)
                       }
                   },{
                        field:'tax_tate_warehouse_bill2', width: 100, title: '开票', formatter: ({cellValue,row}) => {
                            if (row.type_name != '收款单' && row.type_name != '订金单'){
                                return cellValue
                            }
                        }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/clientReceivableDetail/searchHeard', this.pickerDate,this.formData)
                       }
                   },{
                        field:'has_invoice', width: 100, title: '已开票', formatter: ({cellValue,row}) => {
                            if (row.type_name != '收款单' && row.type_name != '订金单'){
                                return cellValue
                            }
                        }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/clientReceivableDetail/searchHeard', this.pickerDate,this.formData)
                       }
                   },{
                        field:'invoice_remark', width: 170, title: '发票单备注', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/clientReceivableDetail/searchHeard', this.pickerDate,this.formData)
                       }
                   },{
                        field:'remark', width: 170, title: '备注', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/clientReceivableDetail/searchHeard', this.pickerDate,this.formData)
                        }
                    },
                ],
                tableDataMain: [],
                filterData: {},
                sortData: {},
           }
       },
        methods: {

            //付款单位
            companyNameChangeEvent(item, nameType) {
                //console.log(item,nameType)
                /*if (nameType == 'customer_name') {
                    this.formData.company_name = item.customer_name;
               } else */
                if (nameType == 'supplier_name') {
                    this.formData.company_name = item.supplier_name;
               }else{
                    this.formData.company_name = item.customer_name;
                }
                this.formData.company_uuid = item.company_uuid;
                delete this.filterData.customer_name;//删除元素 删除对象属性
           },

            search() {
                const loading = this.$loading({
                    lock: true,
                    text: '饮茶先啦，数据查询中，请耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
               });
                let searchUrl = '/admin/clientReceivableDetail/search'
                this.$axios({
                    method: 'POST',
                    url: searchUrl,
                    data: {
                        currentPage: this.tablePage.currentPage,
                        pageSize: this.tablePage.pageSize,
                        sortData: this.sortData,
                        filterData: this.filterData,
                        pickerDate: this.pickerDate,
                        formData: this.formData
                   }
               }).then((response) => {         //这里使用了ES6的语法
                    loading.close();
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200) {
                        this.before_balance_company = response.data.before_balance_company
                        this.end_balance_company = response.data.end_balance_company
                        this.tableDataMain = response.data.page.list
                        this.tablePage.pageSize = response.data.page.pageSize
                        this.tablePage.total = response.data.page.totalRow
                        this.tablePage.currentPage = response.data.page.pageNumber
                   }
               }).catch((error) => {
                    loading.close();
                    console.log(error)
               });
           },
            handlePageChange({currentPage, pageSize}) {
                this.tablePage.currentPage = currentPage
                this.tablePage.pageSize = pageSize
                this.search();
           },

            tableRowClassName({rowIndex}) {
                if (rowIndex % 2 === 1) {
                    return 'success-row';
               } else {
                    return '';
               }
           },
            sumNum(list, field) {
                let count = Number(0)
                list.forEach(item => {
                    count = this.$XEUtils.add(count, item[field]);
               })
                /*if (field === 'the_theory_of_count'){
                    count = this.$XEUtils.commafy(count ? count : '', {digits: 2}) + '';
               }else if(field === 'amount_of_product'){
                    count = this.$XEUtils.commafy(count ? count : '', {digits: 2}) + '';
               }else if(field === 'naked_price'){
                    count = this.$XEUtils.commafy(count ? count : '', {digits: 2}) + '';
               }*/
                //return this.$XEUtils.commafy(count ? count : '', {digits: 2}) + '';
                return count;
           },
            footerMethod({columns, data}) {
                const sums = []
                columns.forEach((column, columnIndex) => {
                    if (columnIndex === 0) {
                        sums.push('合计')
                   } else {
                        if (
                            column.property === 'in_money' || column.property === 'out_money' || column.property === 'before_money'
                        ) {
                            sums.push(this.sumNum(data, column.property))
                        }else if(column.property == 'b_money'){
                            //放最后一个结存数
                            if (data[data.length-1]!= null){
                                sums.push(this.$XEUtils.commafy(data[data.length-1][column.property], {digits: 2}))
                            }
                        } else {
                            sums.push('')
                        }
                   }
               })
                //this.autoComputed();
                // 返回一个二维数组的表尾合计
                return [sums]
           },

       },
        created() {
            this.$nextTick(() => {

                this.$_searchCustomerAndSupplier();
                this.loading = true
                // 手动将表格和工具栏进行关联
                this.$refs.xGrid.connect(this.$refs.xToolbar2)
                if (!this.isBlankVue(this.$route.query.company_uuid)){
                    this.formData.company_uuid = this.$route.query.company_uuid,
                    this.formData.company_name = this.$route.query.company_name,
                    //console.log('this.$route.query.startDate',this.$route.query.startDate)
                    //console.log('this.$route.query.endDate',this.$route.query.endDate)
                    //console.log('start_date',new Date(Date.parse(this.$route.query.startDate.replace(/-/g,  "/"))))
                    //console.log('end_date',new Date(Date.parse(this.$route.query.endDate.replace(/-/g,  "/"))))
                    //console.log('this.XEUtils.toStringDate(this.$route.query.startDate, \'yyyy-MM-dd\')',this.XEUtils.toStringDate(this.$route.query.startDate, 'yyyy-MM-dd'))
                    this.pickerDate.push(new Date(Date.parse(this.$route.query.startDate.replace(/-/g,  "/"))))
                    this.pickerDate.push(new Date(Date.parse(this.$route.query.endDate.replace(/-/g,  "/"))))
                    this.search();
               }
           })
       }
   }
</script>

<style scoped>

</style>
